<template>
  <div class="all">
    <div id="error-message" v-if="errorMessage !== null">
      <span>Error loading data: {{ errorMessage }}</span>
    </div>
    <rules :rules="rulesData" />
  </div>
</template>

<script>
import RuleCards from "../components/RuleCards.vue";

export default {
  name: "All",
  components: {
    rules: RuleCards,
  },
  data() {
    return {
      rulesData: [],
      errorMessage: null,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      Promise.all([this.getRulesData()])
        .then((values) => {
          this.rulesData = values[0];
        })
        .catch((error) => {
          this.errorMessage = error;
          console.error("There was an error loading app data", error);
        });
    },
    async getRulesData() {
      const response = await fetch("data/rules.json");
      const data = await response.json();
      // check for error response
      if (!response.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || response.statusText;
        throw new Error(error);
      }
      return data;
    },
  },
  computed: {},
};
</script>

<style>
#error-message {
}
</style>
